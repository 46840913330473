<template>
  <section>
    <p> Hier kunt u afspraken die afgerond zijn bekijken. <router-link to="/afspraken" class="float-right"><button
          type="button" class="btn btn-info mg-0">Terug naar afspraken</button></router-link>
    </p>

    <br>
    <div class="container-fluid" id="list">
      <div class="row table-wrapper">
        <div class="col-md-12">
          <DataTable :value="appointments" :paginator="true" :rows="15"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[15,30,50]" responsiveLayout="scroll" v-model:filters="filters"
            :globalFilterFields="['AppointmentNumber','DeptorDescription','Title','ReportDate', 'MeetingDate', 'VideoAppointmentDate', 'VideoAppointmentTime', 'Place', 'GeneralProblem']"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="flex justify-content-between">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Zoeken" />
                </span>
              </div>
            </template>
            <Column field="AppointmentNumber" header="Nummer" :sortable="true"></Column>
            <Column field="DeptorDescription" header="Debiteur omschrijving" :sortable="true"></Column>
            <Column field="AppointmentReference" header="Referentie" :sortable="true"></Column>
            <Column field="Place" header="Plaats" :sortable="true"></Column>
            <Column field="PhoneNumber" header="Mobiel" :sortable="true"></Column>
            <Column header="Communicatie" :exportable="false" style="min-width:8rem">
              <template #body="slotProps">
                <Button v-if="slotProps.data['PhoneNumber']" icon="pi pi-whatsapp"
                  class="p-button-rounded p-button-warning mr-2" @click="openWhatsappAppointmentDialog(slotProps)" />
                <Button v-if="slotProps.data['ClientEmail']" icon="pi pi-briefcase"
                  class="p-button-rounded p-button-info mr-2" @click="openEmailDialog(slotProps)" />
              </template>
            </Column>
            <Column header="Aanpassen" :exportable="false" style="min-width:8rem">
              <template #body="slotProps">
                <Button icon="pi pi-history" class="p-button-rounded p-button-success mr-2"
                  @click="openReopenAppointmentDialog(slotProps)" />
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2"
                  @click="openDeleteAppointmentDialog(slotProps)" />
              </template>
            </Column>
            <template #paginatorstart>
            </template>
            <template #paginatorend>
            </template>
          </DataTable>
        </div>
      </div>
      <Dialog v-model:visible="whatsappAppointmentDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="this.selectedAppointment.PhoneNumber">Weet je zeker dat je een bericht wilt sturen naar: {{this.selectedAppointment.PhoneNumber}}?</span>
            <span v-else>Geen telefoonnummer gevonden bij deze afspraak.{{this.selectedAppointment.PhoneNumber}} </span>
        </div>
        <template #footer>
            <Button label="Annuleren" icon="pi pi-times" class="p-button-text" @click="whatsappAppointmentDialog = false"/>
            <Button v-if="this.selectedAppointment.PhoneNumber" label="Versturen" icon="pi pi-check" class="p-button-text" @click="redirectToWhatsapp(this.selectedAppointment.PhoneNumber);" />
        </template>
      </Dialog>
      <Dialog v-model:visible="deleteAppointmentDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Weet je zeker dat je deze afspraak wilt verwijderen?</span>
        </div>
        <template #footer>
            <Button label="Annuleren" icon="pi pi-times" class="p-button-text" @click="deleteAppointmentDialog = false"/>
            <Button label="Verwijderen"  icon="pi pi-check" class="p-button-text" @click="deleteAppointment(this.selectedAppointment);" />
        </template>
      </Dialog>
      <Dialog v-model:visible="reopenAppointmentDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Weet je zeker dat je deze afspraak wilt heropenen?</span>
        </div>
        <template #footer>
            <Button label="Annuleren" icon="pi pi-times" class="p-button-text" @click="reopenAppointmentDialog = false"/>
            <Button label="Afronden"  icon="pi pi-check" class="p-button-text" @click="reopenMeeting(this.selectedAppointment);" />
        </template>
      </Dialog>
      <Dialog v-model:visible="emailDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
          <div class="confirmation-content">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="this.selectedAppointment.ClientEmail">Weet je zeker dat je een mail wilt sturen naar: <b>{{this.selectedAppointment.ClientEmail}}</b>?</span>
              <span v-else>Geen email gevonden bij deze afspraak. {{this.selectedAppointment}} </span>
          </div>
          <template #footer>
              <Button label="Annuleren" icon="pi pi-times" class="p-button-text" @click="emailDialog = false"/>
              <Button v-if="this.selectedAppointment.ClientEmail" label="Ja" icon="pi pi-check" class="p-button-text" @click="sendMail(this.selectedAppointment)" />
          </template>
      </Dialog>
    </div>
  </section>
</template>

<script>
  import AppointmentDataService from "../../services/AppointmentDataService";
  import moment from 'moment';
  import {
    FilterMatchMode
  } from 'primevue/api';

  export default {
    data() {
      return {
        appointments: [],
        errors: [],
        selectedAppointment: null,
        whatsappAppointmentDialog: false,
        deleteAppointmentDialog: false,
        reopenAppointmentDialog: false,
        emailDialog: false,
        filters: {
          'global': {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
          },
          'AppointmentNumber': {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
          },
          'DeptorDescription': {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
          },
          'Title': {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
          },
          'ReportDate': {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
          },
          'MeetingDate': {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
          },
          'VideoAppointmentDate': {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
          },
          'VideoAppointmentTime': {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
          },
          'Place': {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
          },
          'GeneralProblem': {
            value: null,
            matchMode: FilterMatchMode.CONTAINS
          },
        },
      }
    },
    created() {
      AppointmentDataService.getAll().on("value", this.handleData);
    },
    methods: {
      cleanData(data) {
        if (data.StreetNumber == 'null') {
          data.StreetNumber = "";
        }
        if (data.Street == 'null') {
          data.Street = "";
        }
        if (data.Phonenumber != undefined && data.Phonenumber.startsWith("06-")) {
          const phone = data.Phonenumber.replace("06-", "+316").replace(/\s/g, "");
          data.Phonenumber = phone;
        } else if (data.Phonenumber != undefined && data.Phonenumber.startsWith("06")) {
          const phone = data.Phonenumber.replace("06", "+316").replace(/\s/g, "");
          data.Phonenumber = phone;
        }
      },
      handleData(items) {
        let _appointments = [];

        items.forEach(element => {
          let key = element.key;
          let dataVal = element.val();
          var data = JSON.parse(JSON.stringify(dataVal))
          this.cleanData(data);

          if (data.Finished != '0') {
            // Populate the appointments w/ firebase data
            _appointments.push({
              key: key,
              AcceptedAppointment: data.AcceptedAppointment,
              AppointmentDue: data.AppointmentDue,
              AppointmentNumber: data.AppointmentNumber,
              AppointmentReference: data.AppointmentReference,
              ClientEmail: data.ClientEmail,
              DeptorDescription: data.DeptorDescription,
              Finished: data.Finished,
              GeneralProblem: data.GeneralProblem != "null" ? data.GeneralProblem : "",
              Id: data.Id,
              InQueue: data.InQueue,
              MeetingDate: moment(String(data.MeetingDate)).format('DD/MM/YYYY') != "01/01/0001" ? moment(
                String(data.MeetingDate)).format('DD/MM/YYYY') : "",
              PhoneNumber: data.Phonenumber,
              Place: data.Place,
              Adres: data.Street + " " + data.StreetNumber,
              Postalcode: data.Postalcode,
              ReportDate: moment(String(data.ReportDate)).format('DD/MM/YYYY') != "01/01/0001" ? moment(String(
                data.ReportDate)).format('DD/MM/YYYY') : "",
              Street: data.Street,
              StreetNumber: data.StreetNumber,
              StreetSuffix: data.StreetSuffix,
              TermsOfService: data.TermsOfService,
              Timestamp: data.Timestamp,
              Title: data.Title,
              UserId: data.UserId,
              VideoAppointmentDate: moment(String(data.VideoAppointment)).format('DD/MM/YYYY') != "01/01/0001" ?
                moment(String(data.VideoAppointment)).format('DD/MM/YYYY') : "",
              VideoAppointmentTime: moment(String(data.VideoAppointment)).format('HH:mm') != "00:00" ? moment(
                String(data.VideoAppointment)).format('HH:mm') : ""
            });
          }
        });
        // Assing the existing appointments object
        this.appointments = _appointments;
      },
      //Modal's 
      // Whatsapp 
      openWhatsappAppointmentDialog(appointment) {
        var data = JSON.parse(JSON.stringify(appointment.data))
        this.selectedAppointment = data;
        this.whatsappAppointmentDialog = true;
      },
      redirectToWhatsapp(phoneNumber) {
        this.whatsappAppointmentDialog = false;
        if (phoneNumber != null) {
          if (phoneNumber.startsWith("06-")) {
            const cleanTel = phoneNumber.replace("06-", "+316").replace(/\s/g, "");
            // Instead of opening a new tab. This should go to the whatsapp bot. 
            window.open(`http://wa.me/${cleanTel}`)
          } else if (phoneNumber.startsWith("06")) {
            const cleanTel = phoneNumber.replace("06", "+316").replace(/\s/g, "");
            window.open(`http://wa.me/${cleanTel}`)
          } else {
            window.open(`http://wa.me/${phoneNumber.replace(/\s/g, "")}`)
            return 
          }
        }
      },
      // Delete modal
      openDeleteAppointmentDialog(appointment){
        var data = JSON.parse(JSON.stringify(appointment.data))
        this.selectedAppointment = data;
        this.deleteAppointmentDialog = true;
      },
      deleteAppointment(appointment) {
        AppointmentDataService.delete(appointment.key).then(response => this.output = response
          .data).catch(e => {
            // TODO: Send toast
          this.output = e;
        });    
      },
      openReopenAppointmentDialog(appointment){
        var data = JSON.parse(JSON.stringify(appointment.data))
        this.selectedAppointment = data;
        this.reopenAppointmentDialog = true;
      },
      reopenMeeting(appointment){
        AppointmentDataService.update(appointment.key, {
          Finished: '0',
        }).then(response => {
          this.output = response.data;
          // Toast
          location.reload();
        })
        .catch(e => {
          this.output = e;
        })
      },
      openEmailDialog(appointment){
        var data = JSON.parse(JSON.stringify(appointment.data))
        this.selectedAppointment = data;
        this.emailDialog = true;
      },
      sendMail(appointment) {
        alert('FAKE MAIL SEND TRIGGER FOR THE APPOINTMENT TOWARDS MAIL ' + appointment.ClientEmail);
        console.log("Concept mail poof");        
      },
    }
  }
</script>